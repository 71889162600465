import clsx from 'clsx';
import Image from 'next/image';
import React, {FC, useCallback, useContext} from 'react';

import {Theme, useMediaQuery} from '@mui/material';

import {
  SubcomponentsTeaserGridItemComponent,
  SubcomponentsTeaserGridSlideComponent,
  TypoComponentsTeaserGrid,
} from 'lib/api/strapi';

import {getTeaserGridDataLayer} from 'utils/tagManagerHelpers/gtmEventsParsing';

import {Box, Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import StrapiLink from 'components/basic-components/StrapiLink';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import {ConsentContext} from 'components/section-components/ConsentManager';

import {Carousel} from './Carousel';
import styles from './TeaserGrid.module.scss';

const TeaserGrid: FC<TypoComponentsTeaserGrid> = ({
  teaserGridItem,
  teaserGridItemTemporary,
  teaserGridSlide,
  teaserGridSlideTemporary,
}: {
  teaserGridItem: SubcomponentsTeaserGridItemComponent[];
  teaserGridItemTemporary?: SubcomponentsTeaserGridItemComponent[];
  teaserGridSlide: SubcomponentsTeaserGridSlideComponent[];
  teaserGridSlideTemporary?: SubcomponentsTeaserGridSlideComponent[];
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {pushDataLayer} = useContext(ConsentContext);
  const getLinkClickHandler = useCallback(
    ({gridComponent, index}) =>
      ({sliderPosition}) => {
        pushDataLayer(getTeaserGridDataLayer({gridComponent, index, sliderPosition}));
      },
    [pushDataLayer],
  );

  // filter temporary teaser grid items by date
  const filteredTeaserGridItem = teaserGridItemTemporary?.filter(
    item =>
      item.startShowingSlide !== null &&
      item.stopShowingSlide !== null &&
      new Date() > new Date(`${item.startShowingSlide} 00:00:00`) &&
      new Date() < new Date(`${item.stopShowingSlide} 23:59:59`),
  );

  // merge teaser grid items, but only 2 items will be displayed (temporary ones have priority)
  const teaserGridItems = [...(filteredTeaserGridItem || []), ...teaserGridItem];

  return (
    <Box className={styles.wrapper}>
      <Box className={clsx({[styles.gridContainer]: true})}>
        <Grid container columnSpacing={{xxs: 0, md: '2%'}} rowSpacing={{xxs: '20px', md: 0}}>
          <Grid xxs={12} md={7}>
            <Carousel
              getLinkClickHandler={getLinkClickHandler}
              slides={teaserGridSlide}
              slidesTemporary={teaserGridSlideTemporary}
            />
          </Grid>
          <Grid xxs={12} md={5}>
            {teaserGridItems?.slice(0, 2).map((item, index) => {
              return (
                <Box
                  key={item.id}
                  sx={{position: 'relative', marginBottom: index === 0 ? '20px' : null}}
                >
                  <StrapiLink
                    className={styles.imageLink}
                    style={{height: '100%'}}
                    linkProps={item.link}
                    onClick={getLinkClickHandler({index: index + 1, gridComponent: item}) as any}
                  >
                    <Box className={styles.background} />
                    <Image
                      priority={index === 0}
                      className={styles.imageSmall}
                      src={
                        isDesktop
                          ? item?.image?.data?.attributes?.url
                          : item?.imageMobile?.data?.attributes?.url
                          ? item?.imageMobile?.data?.attributes?.url
                          : item?.image?.data?.attributes?.url
                      }
                      sizes="(max-width: 769px) 100vw,  31vw"
                      height={
                        isDesktop
                          ? item?.image?.data?.attributes?.height
                          : item?.imageMobile?.data?.attributes?.height
                          ? item?.imageMobile?.data?.attributes?.height
                          : item?.image?.data?.attributes?.height
                      }
                      width={
                        isDesktop
                          ? item?.image?.data?.attributes?.width
                          : item?.imageMobile?.data?.attributes?.width
                          ? item?.imageMobile?.data?.attributes?.width
                          : item?.image?.data?.attributes?.width
                      }
                      alt=""
                      quality={80}
                    />
                    <Box className={styles.smallGridItemContentWrapper}>
                      {item?.subtitle ? (
                        <Typography component={item?.subTitleLevel} className={styles.subtitle}>
                          {item?.subtitle}
                        </Typography>
                      ) : null}
                      <Typography
                        component={item?.titleLevel}
                        className={styles.title}
                        variant="h2"
                      >
                        {item?.title}
                      </Typography>
                      <Box className={styles.linkWrapper}>
                        <Typography className={styles.linkText} variant="buttonCards">
                          {item?.link?.linkText}
                        </Typography>
                        <CustomIcon name="arrow" className={styles.icon} />
                      </Box>
                    </Box>
                  </StrapiLink>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorBoundary(TeaserGrid, ' TeaserGrid');
